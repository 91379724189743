import React, { useState } from "react";
import IbizLogo from "../Assests/Images/iBizAccounts.png";
import { Link } from "react-router-dom";
import Axios from "axios";
// import Select from "react-select";
import Swal from "sweetalert2";

export default function SupplierRegister() {

    // const [userDataSession, setUserDataSession] = useState([]);
    const [addSubmitBtnLoading, setAddSubmitBtnLoading] = useState(false);
    const [addAddressType, setAddAddressType] = useState('Office');
    const [addAccountType, setAddAccountType] = useState('Savings');

      // #region Session for userdetails
//   useEffect(() => {
//     const userDataString = sessionStorage.getItem("userData");
//     if (userDataString) {
//       const userData = JSON.parse(userDataString);
//       setUserDataSession(userData);
//     } else {
//       console.log("User data not found in sessionStorage");
//     }
//   }, []);


    const [addFormData, setAddFormData] = useState({
        orgid: "41028",
        userid: "0",
        Supplier: {
            SupplierName: "",
            RegistrationNo: "",
            GSTIN: "",
            Status: "Draft",
            PayTermDays: "",
            ContactName: "",
            EmailId: "",
            PhoneNo: "",
            AlternatePhoneNo: "0",
            Website: "",
            AddressType: "",
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            Country: "India",
            ZIPCode: "",
            Notes: "",
            IsIGST: 1,
            IsVendor: 1,
            IsActive: 1,
            CreatedBy: "0"
        },
        bank:
            {
            Id: 0,
            BankName: "",
            AccountNumber: "",
            IFSCCode: "",
            AccountType: "",
            IsActive: 1
            }
    });

  const handleAddAddressSelectChange = (event) => {
    setAddAddressType(event.target.value);
  };

  const handleAddAccountSelectChange = (event) => {
    setAddAccountType(event.target.value);
  };

  const handleAddInputChange = (e) => {
      const { name, value } = e.target;
      const [objectName, key] = name.split(".");

      if (objectName && key) {
        setAddFormData((prevState) => ({
          ...prevState,
          [objectName]: {
            ...prevState[objectName],
            [key]: value,
          },
        }));
      } else {
        setAddFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
  };

  const handleAddSubmit = (e) => {
      e.preventDefault();
      setAddSubmitBtnLoading(true);

      addFormData.orgid = "41028";
      addFormData.Supplier.Id = 0;
      addFormData.userid = 0;
      addFormData.Supplier.CreatedBy = "0";
      addFormData.Supplier.AddressType = `${addAddressType}`;
      addFormData.bank.AccountType = `${addAccountType}`;

      console.log(addFormData, "sending data to add api.");

      Axios.post(`http://localhost:3004/supplier/InsertSuppliers`, addFormData)
        .then((response) => {
          console.log(response.data, "resposnse data...");
          if (response) {
            setAddSubmitBtnLoading(false);

            // Show success message
            Swal.fire({
              title: `Registration Successfull.`,
              icon: "success",
            }).then((result) => {
              // Reload the page after the user acknowledges the success message
              if (result.isConfirmed || result.isDismissed) {
                // window.location.reload();
              }
            });
          } else {
            setAddSubmitBtnLoading(false);
            Swal.fire({
              title: "Error",
              text: "Failed to Request Submit.",
              icon: "error",
            });
          }
        })

        .catch((error) => {
          setAddSubmitBtnLoading(false);
          // Handle error
          console.error("Error adding category:", error);
        });
  };

  return (
    <div className="main-wrapper">
      <div className="header">
        <div className="header-left active">
          <a href="index.html" className="logo logo-normal">
            <img src={IbizLogo} alt="" />
          </a>
          <a href="index.html" className="logo logo-white">
            <img src={IbizLogo} alt="" />
          </a>
          <a href="index.html" className="logo-small">
            <img src={IbizLogo} alt="" />
          </a>
          <a id="toggle_btn">
            <i data-feather="chevrons-left" className="feather-16"></i>
          </a>
        </div>

        {/* <a id="mobile_btn" className="mobile_btn" href="#sidebar">
                    <span className="bar-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </a> */}

        <ul className="nav user-menu">
          <li className="nav-item nav-searchinputs">
            <div className="top-nav-search">
              <a className="responsive-search">
                <i className="fa fa-search"></i>
              </a>
              <form action="#" className="dropdown" >
                <div
                  className="searchinputs dropdown-toggle"
                  id="dropdownMenuClickable"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="false"
                >
                  <input type="text" placeholder="Search" />
                  <div className="search-addon">
                    <span>
                      <i data-feather="x-circle" className="feather-14"></i>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </li>

          <li className="nav-item dropdown has-arrow main-drop">
            <a
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-letter text-primary">RK</span>
                <span className="user-detail">
                  <span className="user-name">Ram Krishna</span>
                  <span className="user-role"></span>
                </span>
              </span>
            </a>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <div className="profileset">
                  <span className="user-img">
                    <img src="assets/img/profiles/avator1.jpg" alt="" />
                    <span className="status online"></span>
                  </span>
                  <div className="profilesets">
                    <h6>John Smilga</h6>
                    <h5>Super Admin</h5>
                  </div>
                </div>
                <hr className="m-0" />
                <a className="dropdown-item" href="profile.html">
                  {" "}
                  <i className="me-2" data-feather="user"></i> My Profile
                </a>
                <a className="dropdown-item" href="general-settings.html">
                  <i className="me-2" data-feather="settings"></i>Settings
                </a>
                <hr className="m-0" />
                <Link to="/">
                  <a className="dropdown-item logout pb-0">
                    <img
                      src="assets/img/icons/log-out.svg"
                      className="me-2"
                      alt="img"
                    />
                    Logout
                  </a>
                </Link>
              </div>
            </div>
          </li>
        </ul>

        <div className="dropdown mobile-user-menu">
          <a
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item">My Profile</a>
            <a className="dropdown-item">Settings</a>
            <Link to="/">
              <a className="dropdown-item">Logout</a>
            </Link>
          </div>
        </div>
      </div>
      <div className="page-wrapper ">
        <div className="content">
          <h4>Supplier Registration</h4>
          <div className="custom-modal-two mt-3">
            <div className="modal-contnt">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-body custom-modal-body">
                    <ul
                      className="nav nav-tabs tab-style-2 nav-justified mb-3 d-sm-flex d-block"
                      id="myTab1"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="order-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#order-tab-pane-add"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true"
                        >
                          <i className="fa-regular fa-address-book me-1 align-middle"></i>
                          Personal Info
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="confirmed-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#confirm-tab-pane-add"
                          type="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected="false"
                        >
                          <i className="fa-solid fa-building-columns me-1 align-middle"></i>
                          Bank Details
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="shipped-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#shipped-tab-pane-add"
                          type="button"
                          role="tab"
                          aria-controls="contact-tab-pane"
                          aria-selected="false"
                        >
                          <i className="fa-solid fa-house-user me-1 align-middle"></i>
                          Address
                        </button>
                      </li>
                    </ul>
                    <form onSubmit={handleAddSubmit}>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active text-muted "
                          id="order-tab-pane-add"
                          role="tabpanel"
                          aria-labelledby="home-tab-1"
                          tabIndex={0}
                        >
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Supplier Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Supplier.SupplierName"
                                  value={addFormData.Supplier.SupplierName || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Organization name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="Supplier.EmailId"
                                  value={addFormData.Supplier.EmailId || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter email"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Phone</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Supplier.PhoneNo"
                                  value={addFormData.Supplier.PhoneNo || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter phone no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Contact Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Supplier.ContactName"
                                  value={addFormData.Supplier.ContactName || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter contact name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Alternate Phone No</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="Supplier.AlternatePhoneNo"
                                  value={addFormData.Supplier.AlternatePhoneNo || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter alternate no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Reg No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Supplier.RegistrationNo"
                                  value={addFormData.Supplier.RegistrationNo || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter registration no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Payterm</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="Supplier.PayTermDays"
                                  value={addFormData.Supplier.PayTermDays || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter payterm"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>GSTIN</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Supplier.GSTIN"
                                  value={addFormData.Supplier.GSTIN || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter GST no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Website</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Supplier.Website"
                                  value={addFormData.Supplier.Website || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter GST no"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-blocks">
                                <label>Description</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="Supplier.Notes"
                                  value={addFormData.Supplier.Notes || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter notes..."
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade  text-muted"
                          id="confirm-tab-pane-add"
                          role="tabpanel"
                          aria-labelledby="profile-tab-2"
                          tabIndex={0}
                        >
                          <div className="row">
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="bank.BankName"
                                  value={addFormData.bank.BankName || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter bank name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>Account No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="bank.AccountNumber"
                                  value={addFormData.bank.AccountNumber || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter account number"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>IFSC Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="bank.IFSCCode"
                                  value={addFormData.bank.IFSCCode || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter IFSC code"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>Account Type</label>
                                <select
                                  className="form-select"
                                  name="AddAccountType"
                                  value={addAccountType}
                                  onChange={handleAddAccountSelectChange}
                                >
                                  <option value="Savings">Savings</option>
                                  <option value="Current">Current</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade text-muted"
                          id="shipped-tab-pane-add"
                          role="tabpanel"
                          aria-labelledby="contact-tab"
                          tabIndex={0}
                        >
                          <div className="row">
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>Country</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value="India"
                                  style={{ cursor: "not-allowed" }}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Supplier.City"
                                  value={addFormData.Supplier.City || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter city"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-10 col-10">
                              <div className="input-blocks">
                                <label>State</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Supplier.State"
                                  value={addFormData.Supplier.State || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter state"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>ZipCode</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="Supplier.ZIPCode"
                                  value={addFormData.Supplier.ZIPCode || ""}
                                  onChange={handleAddInputChange}
                                  placeholder="Enter pipncode"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="input-blocks">
                                <label>Address Type</label>
                                <select
                                  className="form-select"
                                  name="AddressType"
                                  value={addAddressType}
                                  onChange={handleAddAddressSelectChange}
                                >
                                  <option value="Office">Office</option>
                                  <option value="Warehouse">Warehouse</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-0 input-blocks">
                                <label className="form-label">Address</label>
                                <textarea
                                  className="form-control mb-1"
                                  placeholder="Enter full address"
                                  name="Supplier.AddressLine1"
                                  value={addFormData.Supplier.AddressLine1 || ""}
                                  onChange={handleAddInputChange}
                                />
                                <p>Maximum 600 Characters</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        {/* <button
                                type="button"
                                className="btn btn-cancel me-2"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button> */}
                        <button type="submit" className="btn btn-submit">
                          {addSubmitBtnLoading ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
