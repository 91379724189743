import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SupplierRegister from './Components/SupplierRegister';
import SignIn from './Components/SignIn';


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="" element={<SignIn />} />
          <Route path="supplier-registration" element={<SupplierRegister />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
