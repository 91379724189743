import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import IbizLogo from '../Assests/Images/iBizAccounts.png';
import SideImg from '../Assests/Images/5.png';
// import '../Assests/CSS/custom.css';

export default function SignIn() {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [otpError, setOtpError] = useState('');
    const [displayEmail, setDisplayEmail] = useState(true);
    const [displayOtp, setDisplayOtp] = useState(false);
    const [email, setEmail] = useState('');
    const otpInputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
    const navigate = useNavigate();

    useEffect(() => {
        const handleBackspace = (e) => {
            if (e.key === 'Backspace') {
                const index = otp.findIndex(digit => !digit);
                if (index > 0) {
                    otpInputRefs[index - 1].current.focus();
                }
            }
        };

        window.addEventListener('keydown', handleBackspace);

        return () => {
            window.removeEventListener('keydown', handleBackspace);
        };
    }, [otp, otpInputRefs]);

    const handleOtpChange = (index, value) => {
        if (!/^\d*$/.test(value)) {
            setOtpError('Please enter only numeric values');
            return;
        }

        const newOtp = [...otp];
        newOtp[index] = value;

        if (value && index < otp.length - 1) {
            otpInputRefs[index + 1].current.focus();
        }

        setOtp(newOtp);
        setOtpError('');
    };

    const handleDisplayOtp = () => {
        if (!email) {
            setErrorMessage('Please enter your email.');
            return;
        }
        setDisplayEmail(false);
        setDisplayOtp(true);
        sessionStorage.setItem('email', email);
    };

    const handleSubmitOtp = () => {
        // Here you can add logic to verify the OTP
        navigate('/supplier-registration');
    };

    return (
        <div className="main-wrapper d-flex">
            <div className="account-content flex-grow-1 d-flex col-md-6 col-lg-6 col-12">
                <div className="login-wrapper flex-grow-1 d-flex">
                    <div className="login-content flex-grow-1">
                        {displayEmail && (
                            <form>
                                <div className="login-userset">
                                    <div className="login-logo logo-normal">
                                        <img src={IbizLogo} alt="Ibizlogo" />
                                    </div>
                                    <div className="login-userheading">
                                        <h3>Sign In</h3>
                                        <h4>Perennial POS application has features that helps you to build <br /> solid relationships with customers, track sales activities, <br />automate and keep sales processes stremlined.</h4>
                                    </div>
                                    <div className="form-login mb-3">
                                        <label className="form-label">Email Id</label>
                                        <div className="form-addons position-relative">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter email address"
                                                required
                                                autoComplete="off"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <i className="fa-regular fa-envelope position-absolute end-0 me-3" style={{ top: '50%', transform: 'translateY(-50%)' }}></i>
                                        </div>
                                    </div>
                                    <p className="text-danger fw-bold">{errorMessage}</p>
                                    <div className="form-login">
                                        <button type="button" className="btn btn-login" onClick={handleDisplayOtp}>
                                            {loading ? 'Signing in...' : 'Sign In'}
                                        </button>
                                    </div>
                                    <div className="signinform">
                                        <h4>
                                            New on our platform?
                                            <Link to='/' className="hover-a"> Create an account</Link>
                                        </h4>
                                    </div>
                                    <div className="form-sociallink">
                                        <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                                            <p>Copyright © 2024 <a href="http://www.perennialcode.com/" target="_blank" rel="noreferrer">Perennialcode.</a> All rights reserved</p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                        {displayOtp && (
                            <div className="p-2 text-center">
                                <div className="login-logo logo-normal">
                                    <img src={IbizLogo} alt="Ibizlogo" />
                                </div>
                                <h4>Enter OTP</h4>
                                <div>
                                    <span>A code has been sent to <b className='text-success'>{email}</b></span>
                                </div>
                                <div id="otp" className="inputs d-flex flex-row justify-content-center mt-5">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            className="m-2 text-center form-control shadow"
                                            type="text"
                                            maxLength="1"
                                            style={{ width: '2.7rem' }}
                                            value={digit}
                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                            ref={otpInputRefs[index]}
                                            required
                                        />
                                    ))}
                                </div>
                                {otpError && <p style={{ color: 'red' }}>{otpError}</p>}
                                <button type='button' className='btn btn-primary mt-3 text-capitalize' onClick={handleSubmitOtp}>Submit</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="side-img-wrapper col-md-6 col-lg-6 col-12 d-none d-md-block d-lg-block">
                <img src={SideImg} alt="Side" className="side-img" />
            </div>
        </div>
    );
}
